<template>
  <div class="px-5" id="page-files">
    <Confirm
      title="Bekräfta"
      message="Är du säker på att du vill radera filen?"
      ref="deleteFileConfirm"
      type="success"
      @confirm="onDeleteFileConfirm"
    />
    <h5 class="mb-6 ml-2">{{ $t('FILE.HEADER') }}</h5>

    <input hidden type="file" id="file" ref="file" v-on:change="handleFileUpload()" />

    <FilesTable
      :items="files"
      @createFileClicked="createFileClicked"
      @selectFileClicked="select_file"
      @deleteFileClicked="deleteFileClicked"
      @downloadFileClicked="downloadFile"

    />

    <FileEditorModal
      :item="form"
      ref="file-editor-modal"
      @updated="file_updated"
    />

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";
</style>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import FilesTable from '@/view/pages/ml/files/FilesTable.vue';
import Confirm from '@/view/components/Confirm.vue';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import TipLabel from '@/view/components/TipLabel.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import FileEditorModal from '@/view/pages/ml/files/FileEditorModal.vue';

export default {
  name: 'files',
  mixins: [ toasts ],
  components: {
    FilesTable,
    Confirm,
    TipLabel,
    FileEditorModal
},
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods'])
  },
  mounted() {
    this.loadFiles();
  },
  data() {
    return {
      form: {
        file_id: '',
        name: '',
        ext: '',
        filepath: '',
        import_opt: {},
        export_opt: {},
        public_path: '',
        user_id: '',
        company_id: '',
        created_at: '',
        size_kb: '',
        period_id: '',
        type: '',
        status: '',
        comments: []
      },
      public_link: false,
      member_visible: false,
      creating: false,
      editing: false,
      toDelete: null,
      files: [],
      file: ''
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadFiles();
      }
    },
    currentPeriodId(newValue, oldValue) {
      if (this.currentCompanyId) this.loadFiles();
    }
  },
  methods: {

    file_updated(file) {
      this.toastr('success', this.$t('COMMON.OK'), this.$t('FILE.UPDATED'));

      this.$refs['file-editor-modal'].hide();
    },

    async select_file(file_id) {

      try {
        const res = await axios.get(`/file/${file_id}?company_id=${this.currentCompanyId}`);

        if (res.status === 200) {
          this.form = res.data;
          this.creating = false;
          this.editing = true;

          this.$refs['file-editor-modal'].show();
        }

      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta filen');
      }

    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.uploadFile();
    },
    saveFile() {
      let that = this;
      axios
        .put(`/file/${this.form.file_id}`, this.form)
        .then(res => {
          if (res.status === 200) {
            let index = that.files.findIndex(item => item.file_id == res.data.file_id);
            that.files[index] = res.data;
            // Vue3 compatability for Vue.set
            that.files = [...that.files];

            this.toastr('success', this.$t('COMMON.OK'), 'Filen har uppdaterats');
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte spara filen');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte spara filen');
        });
    },
    selectFileForUpload() {
      this.$refs.file.click();
    },
    uploadFile() {
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('file_id', this.form.file_id);
      formData.append('file_source', 'file');
      formData.append('file_type', 'FILE_OTHER');
      formData.append('form_file_id', this.form.file_id);
      formData.append('company_id', this.currentCompanyId);
      formData.append('period_id', this.currentPeriodId);

      axios
        .post('/fileupload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          this.loadFiles(res.data.fileobjs[0].file_id);

          this.toastr('success', this.$t('COMMON.OK'), 'Filen laddades upp');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ladda upp fil');
        });
    },
    getFilename(path) {
      return path.replace(/^.*[\\\/]/, '');
    },
    async loadData() {
      if (!this.currentCompanyId) {
        return;
      }
      if (!this.titles) {
        return;
      }

      this.loadFiles();
    },
    createFileRecord() {
      axios
        .post('/file', this.form)
        .then(res => {
          if (res.status === 201) {
            this.creating = false;
            this.form = res.data.file;
            this.toastr('success', this.$t('COMMON.OK'), 'Filen skapades');
            this.loadFiles();
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa filen');
        });
    },
    createFileClicked() {
      // this.creating = false;
      // this.editing = true;

      // this.scrollToEditFile();

      // this.form.company_id = this.currentCompanyId;
      // this.form.period_id = this.currentPeriodId;

      this.selectFileForUpload();
    },
    downloadFile(file_id) {

      axios
        .get(`/file/${file_id}`)
        .then(res => {
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta filen');
        });
    },
    deleteFileClicked(file_id) {
      this.toDelete = file_id;
      this.$refs['deleteFileConfirm'].show();
    },
    onDeleteFileConfirm() {
      this.deleteFile(this.toDelete);
      this.toDelete = null;
    },
    deleteFile(file_id) {
      axios
        .delete(`/file/${file_id}?company_id=${this.currentCompanyId}`)
        .then(res => {
          this.files = this.files.filter(item => item.file_id !== file_id);

          this.creating = false;
          this.editing = false;

          this.toastr('success', this.$t('COMMON.OK'), 'Filen togs bort');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ta bort filen');
        });
    },

    getLangText(lang_text, lang) {
      for (var i = 0; i < lang_text.length; ++i) {
        if (lang_text[i].lang === lang) {
          return lang_text[i].text;
        }
      }

      return 'Språköversättning saknas';
    },
    loadFiles(show_file_id = undefined) {
      this.files = [];
      axios
        .get(`/file?company_id=${this.currentCompanyId}&period_id=${this.currentPeriodId}`)
        .then(res => {
          if (res.status === 200) {
            this.files = res.data;

            if (show_file_id !== undefined) {
              const f = this.files.find(item => item.file_id === show_file_id);

              if (f !== undefined) {
                this.form = f;
              }
            }
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista filer');
        });
    },
    scrollToEditFile() {
      var that = this;
      setTimeout(function() {
        const el = that.$el.getElementsByClassName('scroll-to-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },

    downloadFileClicked() {
      downloadWithAxios(get_base_url() + `/dlfile/${this.form.linkstr}`, this.form.name);
    },
    uploadToFortnoxClicked() {
      axios
        .post(`/fortnox/file`, {
          file_id: this.form.file_id,
          path: '/'
        })
        .then(res => {
          if (res.status === 201) {
            this.toastr('success', this.$t('COMMON.OK'), 'Fil skickad till Fortnox');
          }
          else if (res.status === 404) {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hitta fil');
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Odefinerat fel');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Gick inte att skicka till Fortnox');
        });
    }
  }
};
</script>
